import { memo } from 'react';

import { useCheckLinkUpdates, usePaymentLink } from '@/hooks';
import { Container, Divider, Typography } from '@mui/material';

import styles from './styles.module.scss';

const LinkDetails = memo(function LinkDetails() {
  const { data } = usePaymentLink({ shouldUpdateStatus: true });
  useCheckLinkUpdates();

  return (
    <>
      <Container className={styles.linkDetailsContainer}>
        <Typography variant='body_sm'>{data?.reference}</Typography>
        <Typography display='flex' gap='6px' variant='title_lg'>
          {data?.currency.toUpperCase()}
          <Typography variant='title_lg'>
            {Number(data?.bill.billAmount || 0).toFixed(
              data?.currencyPrecision || 2,
            )}
          </Typography>
        </Typography>
      </Container>
      {data?.description && (
        <>
          <Container className={styles.descriptionContainer}>
            <Typography
              variant='caption'
              color='var(--onSurfaceColors-highEmphasis)'
            >
              {data?.description}
            </Typography>
          </Container>
          <Divider />
        </>
      )}
    </>
  );
});

export default LinkDetails;
